﻿/* globals HomeIndexDTO, __tcfapi */
require('../Buscador/buscador');
import HabEvents from '../../Global/habitaclia-events';
var jquery = require('jquery');

(function () {
  'use strict';

  var __HomeIndexDTO;
  var HomeIndex = function (HomeIndexDTO) {
    __registerEvents.call(this);
    __HomeIndexDTO = HomeIndexDTO;
    this.__scrolled = false;
    this.__$header = jquery('#header');
  };

  var __registerEvents = function () {
    var self = this;

    jquery(window).scroll(function () {
      __callbackScrollHome.call(self, jquery(this));
    });

    jquery(window).ready(function () {
      __addTcfApiListener.call(self);
    });

    $('#home-online-appraisal-button').click(function() {
        jquery.publish(HabEvents.EventsEnum.PropertyValuationLandingButtonClicked, 'homepage');
    });

    $('#home-sell-with-agency-button').click(function () {
        jquery.publish(HabEvents.EventsEnum.SellWithAgencyButtonClicked, 'homepage');
    });
      
  };

  var __callbackScrollHome = function ($window) {
    if ($window.scrollTop() > 1 && !this.__scrolled) {
      this.__$header.css('background-color', 'rgba(255,102,0,0.91)');
      this.__scrolled = true;
    }
    if ($window.scrollTop() < 100 && this.__scrolled) {
      this.__$header.css('background-color', 'rgba(0,0,0,0.0)');
      this.__scrolled = false;
    }
    if ($window.scrollTop() === 0 && __HomeIndexDTO.IsMobileApp === 'True') {
      this.__$header.show();
    }
    };

  var __addTcfApiListener = function () {
    __tcfapi && __tcfapi('addEventListener', 2, __callbackTcfApi);
  };

  var __callbackTcfApi = function (tcData, success) {
    if (success && tcData.eventStatus === 'useractioncomplete') {
      var consents = tcData.purpose.consents;
      if (!consents[5] || !consents[6] || !consents[10]) {
        jquery('.menu-home').hide();
        jquery('.listado-busquedas-home').hide();
      }
      __unregisterTcfApiListener(tcData);
    }
  };

  var __unregisterTcfApiListener = function (tcData) {
    __tcfapi &&
      __tcfapi(
        'removeEventListener',
        2,
        function () {},
        tcData.listenerId
      );
  };

  HomeIndex.prototype = {
    constructor: HomeIndex
  };

  if (typeof module !== 'undefined' && module.exports) {
    module.exports = new HomeIndex(HomeIndexDTO);
  } else {
    window.HomeIndex = new HomeIndex(HomeIndexDTO);
  }
})();
