﻿
(function () {
    "use strict";

    var Utils = function () {

    };

    var __getCodigoAnuncio = function (codEmp, codInm) {
        var codInmAnuncio = ('0000000000000000' + codInm).slice(-9);
        var codAnuncio = codEmp + codInmAnuncio;
        return codAnuncio;
    };

    var __getUrlParameter_From_Given_URL = function (paramName, url) {
        var results = new RegExp('[\?&]' + paramName + '=([^&#]*)').exec(url);
        if (results === null) {
            return null;
        }
        else {
            return results[1] || 0;
        }
    };

    var __getQueryString_From_Given_URL = function (url) {
        var queryString = url.substr(url.indexOf('?'), url.length);
        return queryString;
    };

    var __existStringInArray = function (elem, array) {
        if (!elem) return false;
        for (var i = 0; i < array.length; i++) {
            if (array[i] === elem) {
                return true;
            }
        }
    };

    Utils.prototype = {
        constructor: Utils,
        GetCodigoAnuncio: __getCodigoAnuncio,
        GetURLParameterFromGivenURL: __getUrlParameter_From_Given_URL,
        GetQueryStringFromGivenURL: __getQueryString_From_Given_URL,
        ExistStringInArray: __existStringInArray
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new Utils();
    }
    else {
        window.Utils = new Utils();
    }
}());