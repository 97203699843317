﻿require('jquery.tiny-pubsub');

(function () {
    'use strict';

    var Events = function () {
        this.EventsEnum = {
            AbrirMenu: 'AbrirMenu',
            AddAlertaListado: 'AddAlertaListado',
            AddAlertaRegistroCorreo: 'AddAlertaRegistroCorreo',
            AdDiscarded: "AdDiscarded",
            AdDiscardedSuccess: "AdDiscardedSuccess",
            AdRecovered: "AdRecovered",
            AdWordsConversion: 'AdWordsConversion',
            AlertAdded: 'AlertAdded',
            AlertAddedSuccess: 'AlertAddedSuccess',
            AreaPrivada: 'AreaPrivada',
            CambioOrdenacionLista: 'CambioOrdenacionLista',
            CambioVistaLista: 'CambioVistaLista',
            CerrarMenu: 'CerrarMenu',
            ChangePvpMax: "ChangePvpMax",
            ChangePvpMin: "ChangePvpMin",
            ClassicAspLogged: 'ClassicAspLogged',
            ClickAnteriorElementoAgregador: "ClickAnteriorElementoAgregador",
            ClickAnuncioAdyacenciasLista: 'ClickAnuncioAdyacenciasLista',
            ClickAnuncioDestacadoLista: 'ClickAnuncioDestacadoLista',
            ClickAnuncioLive: "ClickAnuncioLive",
            ClickAnuncioModuloLiderLista: 'ClickAnuncioModuloLiderLista',
            ClickAnuncioObraNuevaInteresanteLista: 'ClickAnuncioObraNuevaInteresanteLista',
            ClickAnuncioOportunidadLista: 'ClickAnuncioOportunidadLista',
            ClickAnuncioPremiumLista: 'ClickAnuncioPremiumLista',
            ClickAvisameSiBajaFicha: 'ClickAvisameSiBajaFicha',
            ClickBulletNotificacionesLive: "ClickBulletNotificacionesLive",
            ClickCalcHipoteca: 'ClickCalcHipoteca',
            ClickCerrarFaldon: 'ClickCerrarFaldon',
            ClickCerrarFoto: 'ClickCerrarFoto',
            ClickCompartirFicha: 'ClickCompartirFicha',
            ClickCompartirFichaItem: 'ClickCompartirFichaItem',
            ClickContactarFicha: 'ClickContactarFicha',
            ClickCrearAlertaDesdeFicha: 'ClickCrearAlertaDesdeFicha',
            ClickDescartarAnuncioDesdeFicha: "ClickDescartarAnuncioDesdeFicha",
            ClickDescartarAnuncioDesdeLista: "ClickDescartarAnuncioDesdeLista",
            ClickFiltroFicha: 'ClickFiltroFicha',
            ClickFlechaAnteriorFicha: 'ClickFlechaAnteriorFicha',
            ClickFlechaSiguienteFicha: 'ClickFlechaSiguienteFicha',
            ClickHeader: 'ClickHeader',
            ClickHotmailModal: 'ClickHotmailModal',
            ClickInmuebleInteresanteFicha: 'ClickInmuebleInteresanteFicha',
            ClickItemAgregador: "ClickItemAgregador",
            ClickListGallery: "ClickListGallery",
            ClickLoginCredentialsFaldon: 'ClickLoginCredentialsFaldon',
            ClickLoginFacebookFaldon: 'ClickLoginFacebookFaldon',
            ClickLoginGoogleFaldon: 'ClickLoginGoogleFaldon',
            ClickMasCaracteristicasFicha: 'ClickMasCaracteristicasFicha',
            ClickMasFiltrosLista: 'ClickMasFiltrosLista',
            ClickNavegacionFoto: 'ClickNavegacionFoto',
            ClickPreviewCommentRate: 'ClickPreviewCommentRate',
            ClickPrimeraFotoFicha: 'ClickPrimeraFotoFicha',
            ClickQuieroContacten: 'ClickQuieroContacten',
            ClickQuieroContactenListado: 'ClickQuieroContactenListado',
            ClickQuieroContactenSimilares: 'ClickQuieroContactenSimilares',
            ClickQuieroContactenSimilaresBtn: 'ClickQuieroContactenSimilaresBtn',
            ClickQuieroContactenSlickBtn: 'ClickQuieroContactenSlickBtn',
            ClickRecuperarAnuncioDesdeFicha: "ClickRecuperarAnuncioDesdeFicha",
            ClickRecuperarAnuncioDesdeLista: "ClickRecuperarAnuncioDesdeLista",
            ClickRemoveAllLive: "ClickRemoveAllLive",
            ClickSiguienteElementoAgregador: "ClickSiguienteElementoAgregador",
            clickSimilarWidemedia: "clickSimilarWidemedia",
            ClickTamanoGaleria: 'ClickTamanoGaleria',
            ClickValorarApp: "ClickValorarApp",
            ClickVerTelefonoFicha: 'ClickVerTelefonoFicha',
            ClickVolverListadoFicha: 'ClickVolverListadoFicha',
            CloseMenusHeader: 'CloseMenusHeader',
            CloseWindowModal: 'CloseWindowModal',
            ConfirmacionSolicitudEnviada: 'ConfirmacionSolicitudEnviada',
            ContactarButtonTop: 'ContactarButtonTop',
            ContactFooterWidemedia: "ContactFooterWidemedia",
            ContactSpecialWidemedia: "ContactSpecialWidemedia",
            CoverClick: 'CoverClick',
            CreatedWindowModal: 'CreatedWindowModal',
            CriteoViewBasket: 'CriteoViewBasket',
            DejarRecibirNotificacionesLive: "DejarRecibirNotificacionesLive",
            DetailPortal: 'DetailPortal',
            DialogSubmitDisableAd: 'DialogSubmitDisableAd',
            DoneDescartarAnuncioDesdeFicha: "DoneDescartarAnuncioDesdeFicha",
            DoneDescartarAnuncioDesdeLista: "DoneDescartarAnuncioDesdeLista",
            DoneRecuperarAnuncioDesdeFicha: "DoneRecuperarAnuncioDesdeFicha",
            DoneRecuperarAnuncioDesdeLista: "DoneRecuperarAnuncioDesdeLista",
            EliminarTodasNotificacionesLive: "EliminarTodasNotificacionesLive",
            ErrorAvisoLegal: 'ErrorAvisoLegal',
            ErroresForm: 'ErroresForm',
            FacebookLoaded: 'FacebookLoaded',
            FacebookLogged: 'FacebookLogged',
            FavoritoAnadido: 'FavoritoAnadido',
            FavoritoAnadirAccion: 'FavoritoAnadirAccion',
            FavoritoEliminado: 'FavoritoEliminado',
            FavoritoEliminarAccion: 'FavoritoEliminarAccion',
            FichaLoaded: 'FichaLoaded',
            FocusToSolicitud: 'FocusToSolicitud',
            InmuebleDescartado: 'InmuebleDescartado',
            InmuebleDescartadoRecuperado: 'InmuebleDescartadoRecuperado',
            IrAFormularioContacto: 'IrAFormularioContacto',
            LoginAjaxOk: 'LoginAjaxOk',
            LoginAjaxOkAfterFavoritoClick: 'LoginAjaxOkAfterFavoritoClick',
            LoginPreviewCommentRateOrigin: 'LoginPreviewCommentRateOrigin',
            LogosCargados: 'LogosCargados',
            LogOut: 'LogOut',
            ModuloLiderCargado: 'ModuloLiderCargado',
            ModuloLiderNoCargado: 'ModuloLiderNoCargado',
            MostrarAnuncioNotificacionLive: "MostrarAnuncioNotificacionLive",
            MostrarBulletNotificacionesLive: "MostrarBulletNotificacionesLive",
            NavButtonFicha: 'NavButtonFicha',
            NavegacionFicha: 'NavegacionFicha',
            NavSwipeFicha: 'NavSwipeFicha',
            NewItemAfterDiscard: "NewItemAfterDiscard",
            NotificationLive: 'NotificationLive',
            OpenLoginModal: 'OpenLoginModal',
            OpenSocialLoginModal: 'OpenSocialLoginModal',
            PropertyValuationLandingButtonClicked: 'PropertyValuationLandingButtonClicked',
            PublishAd: 'PublishAd',
            SellWithAgencyButtonClicked: 'SellWithAgencyButtonClicked',
            PWAEvent: 'PWAEvent',
            RecibirAlertasSolicitud: 'RecibirAlertasSolicitud',
            RecuentoIntentosSolicitud: 'RecuentoIntentosSolicitud',
            RegistrarAlerta: 'RegistrarAlerta',
            RegistrarAlertaOk: 'RegistrarAlertaOk',
            RegistroAlerta: 'RegistroAlerta',
            RegistroAlertaCriterioInmueble: 'RegistroAlertaCriterioInmueble',
            RegistroAlertaCriterioListado: 'RegistroAlertaCriterioListado',
            SegmentTrackLead: 'SegmentTrackLead',
            ShowMessageLegalLoginMobile: 'ShowMessageLegalLoginMobile',
            ShowModalLegalLogin: 'ShowModalLegalLogin',
            ShowWideMedia: 'ShowWideMedia',
            SocialNetworkCallback: 'SocialNetworkCallback',
            SwipeAmpliarFoto: "SwipeAmpliarFoto",
            SwipeFoto: 'SwipeFoto',
            TapAmpliarFoto: "TapAmpliarFoto",
            UrlAjaxSolicitudError: "UrlAjaxSolicitudError",
            UserLogin: 'UserLogin',
            VerFichaFotocasa: 'VerFichaFotocasa',
            VerMasAnunciosEnLaZona: 'VerMasAnunciosEnLaZona',
            WideMedia: 'WideMedia',
			WideMediaThumbnails: 'WideMediaThumbnails'
        };
        this.GTMEcommerceEventsEnum = {
            ClicGtmItemUrl: 'ClicGtmItemUrl'
        };
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new Events();
    }
    else {
        window.UIEvents = new Events();
    }
}());
