﻿(function () {
    "use strict";

    var UIKeyCodes = function () {
        this.UIKeyCodesEnum = {
            BACKSPACE: 8,
            COMMA: 188,
            DELETE: 46,
            DOWN: 40,
            END: 35,
            ENTER: 13,
            ESCAPE: 27,
            HOME: 36,
            LEFT: 37,
            PAGE_DOWN: 34,
            PAGE_UP: 33,
            PERIOD: 190,
            RIGHT: 39,
            SPACE: 32,
            TAB: 9,
            UP: 38,
            ZERO: 48,
            ZETA_MAYUS: 90
        };
    };

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new UIKeyCodes();
    }
    else {
        window.UIKeyCodes = new UIKeyCodes();
    }
}());
